/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { Component, useContext } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext";
import Footer from "~components/Footer";
import GetInTouch from "~components/GetInTouch";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

class ServicesPageComponent extends Component {
  state = {
    expanded: false,
    servicesHoverIndex: null
  };

  componentDidMount() {
    const { appContext } = this.props;

    appContext.setLanguageWidgetColor(`black`);
    appContext.setNavColor(`black`);
  }

  componentWillUnmount() {
    const { appContext } = this.props;

    appContext.setLanguageWidgetColor(`white`);
    appContext.setNavColor(`white`);
  }

  //

  expand = () => {
    this.setState({
      expanded: true
    });
  };

  //

  render() {
    const { frontmatter, location } = this.props;
    const { expanded, servicesHoverIndex } = this.state;

    const parsedClients = [];
    const clientsLength = frontmatter.clients.length;

    frontmatter.clients.forEach((client, index) => {
      let columnIndex;

      if (index < clientsLength * 0.25) {
        columnIndex = 0;
      } else if (index < clientsLength * 0.5) {
        columnIndex = 1;
      } else if (index < clientsLength * 0.75) {
        columnIndex = 2;
      } else {
        columnIndex = 3;
      }

      if (!parsedClients[columnIndex]) {
        parsedClients[columnIndex] = [];
      }

      parsedClients[columnIndex].push(client);
    });

    return (
      <>
        <SEO
          customTitle={frontmatter.title}
          customDescription={frontmatter.seoDescription}
          customKeywords={frontmatter.seoKeywords}
          path={location.pathname}
        />

        <Layout className="services-page w-full relative" location={location}>
          <section className="w-full relative bg-off-white">
            <div className="relative grid pt-16 pb-48 xs:pb-32">
              <h1 className="animation-appear-right animation-delay-3 grid-end-19 grid-start-4 sm:grid-end-22 sm:grid-start-2 f1">
                {frontmatter.heading}
              </h1>

              <p
                className="animation-appear-right animation-delay-4 grid-end-8 grid-start-16 sm:grid-end-22 sm:grid-start-2 mt-12 b2 whitespace-pre-wrap"
                dangerouslySetInnerHTML={{ __html: frontmatter.intro }}
              ></p>

              {frontmatter.bannerImage && (
                <Img
                  className="animation-appear-right animation-delay-5 grid-end-20 grid-start-5 sm:grid-end-22 sm:grid-start-2 mt-16 block"
                  fluid={frontmatter.bannerImage.childImageSharp.fluid}
                  alt="Services Banner"
                />
              )}

              {frontmatter.image && frontmatter.image.childImageSharp && (
                <Img
                  className="animation-appear-right animation-delay-5 grid-end-20 grid-start-5 sm:grid-end-22 sm:grid-start-2 mt-16 block"
                  fluid={frontmatter.image.childImageSharp.fluid}
                  alt={frontmatter.imageAlt || `Article aside`}
                />
              )}

              {frontmatter.ourServices && frontmatter.ourServices.length > 0 && (
                <>
                  <div className="animation-appear-right grid-end-10 grid-start-5 sm:grid-end-22 sm:grid-start-2 mt-32 xs:mt-16">
                    <h3 className="b1">— Our services</h3>
                  </div>

                  <div className="animation-appear-right grid-end-21 grid-start-4 sm:grid-end-22 sm:grid-start-2 xs:mt-6">
                    <ul className="services-page__expanders relative">
                      {frontmatter.ourServices.map((service, index) => {
                        const key = `${service.name}-${index}`;
                        const showItems = servicesHoverIndex === index;

                        return (
                          <li
                            key={key}
                            className="services-page__expander transition-opacity w-full relative flex items-center justify-between my-6 f1"
                            onMouseEnter={() => {
                              this.setState({
                                servicesHoverIndex: index
                              });
                            }}
                            onMouseLeave={() => {
                              this.setState({
                                servicesHoverIndex: null
                              });
                            }}
                          >
                            <span>{service.name}</span>

                            {showItems && (
                              <ul className="absolute top-0 right-0 pt-4">
                                {service.hovers.map(
                                  (serviceHover, serviceHoverIndex) => {
                                    const hoverKey = `${serviceHover}-${serviceHoverIndex}`;

                                    return (
                                      <li
                                        key={hoverKey}
                                        className={`services-page__expander__secondary animation-appear animation-delay-${serviceHoverIndex} f4`}
                                      >
                                        {serviceHover}
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            )}
                          </li>
                        );
                      })}
                    </ul>

                    {/* // */}

                    <ul className="services-page__expanders services-page__expanders--touch relative">
                      {frontmatter.ourServices.map((service, index) => {
                        const key = `${service}-${index}`;
                        const showItems = servicesHoverIndex === index;

                        return (
                          <li
                            key={key}
                            className="services-page__expander relative"
                          >
                            <button
                              type="button"
                              className="w-full relative flex items-center justify-between mt-8 f2"
                              onClick={() => {
                                this.setState(prevState => ({
                                  servicesHoverIndex:
                                    prevState.servicesHoverIndex === null
                                      ? index
                                      : null
                                }));
                              }}
                            >
                              <span>{service.name}</span>
                              <span>{showItems ? `-` : `+`}</span>
                            </button>

                            <ul
                              className={`services-page__expander__touch ${
                                showItems ? `pt-4 pb-8` : ``
                              } relative`}
                            >
                              {showItems &&
                                service.hovers.map(
                                  (serviceHover, serviceHoverIndex) => (
                                    <li
                                      key={serviceHover}
                                      className={`animation-appear animation-delay-${serviceHoverIndex} py-1 f4`}
                                    >
                                      {serviceHover}
                                    </li>
                                  )
                                )}
                            </ul>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </>
              )}

              {parsedClients && parsedClients.length > 0 && (
                <>
                  <section className="grid-end-20 grid-start-5 sm:grid-end-22 sm:grid-start-2 relative mb-12">
                    <div className="mt-32 xs:mt-24">
                      <h3 className="b1">— Who we work with</h3>
                    </div>

                    <div
                      className={`services-page__clients ${
                        expanded ? `expanded` : ``
                      } grid-end-16 grid-start-7 sm:grid-end-22 sm:grid-start-2 relative flex sm:block mt-6 xs:mt-16 overflow-hidden`}
                    >
                      {!expanded && (
                        <div className="services-page__clients__gradient w-full absolute bottom-0 right-0 left-0 z-10 pointer-events-none"></div>
                      )}

                      {parsedClients.map(parsedClient => (
                        <ul
                          key={parsedClient}
                          className="services-page__client-list w-1/4 sm:w-full relative block"
                        >
                          {parsedClient.map(client => (
                            <li key={client} className="py-1 b2">
                              {client}
                            </li>
                          ))}
                        </ul>
                      ))}
                    </div>
                  </section>

                  {!expanded && (
                    <div className="grid-end-12 xs:grid-end-24 grid-start-13 xs:grid-start-1 relative block xs:text-center">
                      <button
                        type="button"
                        className="cursor-pointer f3"
                        onClick={this.expand}
                      >
                        Show more +
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </section>

          <GetInTouch withNav />

          <Footer />
        </Layout>
      </>
    );
  }
}

//

const ServicesPage = ({ data, location }) => {
  const appContext = useContext(AppContext);
  const { frontmatter } = data.markdownRemark;

  return (
    <ServicesPageComponent
      appContext={appContext}
      frontmatter={frontmatter}
      location={location}
    />
  );
};

export default ServicesPage;

export const query = graphql`
  query ServicesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        intro
        bannerImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        ourServices {
          name
          hovers
        }
        clients
        seoDescription
        seoKeywords
      }
    }
  }
`;
